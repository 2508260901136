import Link from '@components/link/ActiveLink'
import useT from '@hooks/useTranslation'

import styles from 'styles/SearchLinkSection.module.css'

const Links = ({ links, names }) =>
    <div className={styles.links}>
        {
            links.map((link, i) =>
                <Link key={link} href={link} activeClassName={styles.active}>
                    <a className={styles.link}>
                        {useT(names[i])}
                    </a>
                </Link>
            )
        }
    </div>

export default Links