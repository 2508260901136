import { useState, useEffect } from 'react'
import RenderOutInputs from '@components/search/forms/RenderOutInputs'

function ConditionalSection ({ name: _, conditions, inputs, ...rest } ) {
    const [conditionsFulfilled, setConditionsFulfilled] = useState(true)

    watchConditions(conditions, rest)

    useEffect(() => {
        const conditionsAreFullfilled = checkConditions(conditions, rest)
        setConditionsFulfilled(conditionsAreFullfilled)
    })

    useEffect(() => {
        if (!conditionsFulfilled) {
            // TODO: persistence
            unregisterInputs(inputs, rest)
        }

    }, [conditionsFulfilled])

    return conditionsFulfilled
        ? <RenderOutInputs inputs={inputs} {...rest} />
        : null
}

function watchConditions (conditions, { methods: { watch }}) {
    for (const key in conditions) {
        watch(key)
    }
}

function checkConditions (conditions, { methods: { getValues }}) {
    for (const entry of Object.entries(conditions)) {
        const [key, desired] = entry
        if (getValues(key) !== desired) return false
    }

    return true
}

function unregisterInputs (inputs, { methods: { unregister }}) {
    inputs.forEach(({ name }) => unregister(name))
}

export default ConditionalSection