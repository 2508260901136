import Input from '@components/form/Input'
import withMultiselectHint from '@hoc/withMultiselectHint'
import useT from '@hooks/useTranslation'

function MultipleSelect ({ input, ...rest }) {
    const { t, ...inputRest } = input
    const label = useT(t)

    return <Input {...rest} {...inputRest}
        hintMethod={withMultiselectHint}
        label={label} />
}

export default MultipleSelect