import styles from 'styles/Input.module.css'

const LabelText = ({ text, htmlFor, small, required }) => {
    return text
        ? <label className={resolveStyleBasedOnLength(small, required)} htmlFor={htmlFor}>{ text }</label>
        : null
}

function resolveStyleBasedOnLength (small, required) {
    const star = required
        ? ` ${styles.required}`
        : ''

    return small
        ? `${styles.label_text} ${styles.label_small}${star}`
        : `${styles.label_text}${star}`
}

export default LabelText