import { useState, useEffect } from 'react'
import useSWR from 'swr'
import { apiFetcherPost as apiFetcher } from '@api/apiFetcher'
import { HintsBox, hintApiUrl } from '@hoc/withHint'

import styles from 'styles/Hint.module.css'

const withMultiselectHint = ({ name, cache, hint, methods }) => {
    const { url } = hint
    if (!url) return null

    return function Hint ({ children, currentValue, setCurrentValue = ()=>{} }) {
        const [focus, setFocus] = useState(false)
        const [hints, setHints] = useState([])
        const [triggerFetch, setTriggerFetch] = useState(false)

        useEffect(() => {
            if (!focus) return
            setTriggerFetch(true)
        }, [focus])

        const shouldFetch = triggerFetch && focus
        const { data, error } = useSWR(shouldFetch
            ? hintApiUrl(url, { deps: [] })
            : null, apiFetcher)

        if (shouldFetch && data) {
            if (cache) cache.put(data)
            setHints(data)
            setTriggerFetch(false)
        }

        if (shouldFetch && error) {
            setTriggerFetch(false)
        }

        const onClick = (e) => {
            if (e.defaultPrevented) return
            setFocus(!focus)
        }

        const onSelect = (e) => {
            e.preventDefault()

            const selected = e.target.getAttribute('value')
            const alreadySelected = currentValue.includes(selected)

            let value = ''
            if (alreadySelected) {
                value = currentValue.split(',').filter(v => v != selected).join(',')

            } else {
                value = currentValue
                    ? [currentValue,selected].join(',')
                    : selected
            }

            methods.setValue(name, value)
            methods.onChange && methods.onChange({ name, value })
            if (setCurrentValue) setCurrentValue(value)
        }

        return (
            <div
                className={styles.wrapper}
                onClick={onClick}>

                    {children}
                    {focus && hints?.length > 0
                        && <Hints
                                hints={hints}
                                onSelect={onSelect}
                                currentValue={currentValue} />}

            </div>
        )
    }
}

function Hints ({ hints = [], onSelect, currentValue = '' }) {
    if (hints.length === 0) return null

    return (
        <HintsBox>
            { hints.map(hint => {
                const isActive = currentValue.includes(hint)
                return (
                    <li
                        key={hint}
                        value={hint}
                        onClick={onSelect}
                        className={isActive
                            ? styles['active-hint']
                            : styles['hint']}>

                        {hint}
                    </li>
                )
            })}
        </HintsBox>
    )
}

export default withMultiselectHint