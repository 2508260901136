import { Children, cloneElement } from 'react'
import { withRouter } from 'next/router'
import Link from 'next/link'


const ActiveLink = ({ router, children, activeClassName, ...rest }) => {
    const child = Children.only(children)

    const regularClassName = child.props.className || ''
    const applyActiveClass = activeClassName && router.pathname === rest.href
    const className = applyActiveClass
        ? `${regularClassName} ${activeClassName}`.trim()
        : regularClassName


    return <Link {...rest}>{cloneElement(child, { className })}</Link>
}

export default withRouter(ActiveLink)