import LabelText from '@components/form/LabelText'
import withHint from '@hoc/withHint'
import styles from 'styles/Input.module.css'
import rangeStyles from 'styles/RangeInput.module.css'

const RangeInput = ({ register, name, label, hint, methods, small, lastFormEventTime, ...rest }) => {
    const from = `${name}-od`
    const to = `${name}-do`

    const inputFrom = <Input {...{name: from, register, rest }} />
    const inputTo = <Input {...{name: to, register, rest }} />

    const HintFrom = withHint({
        name: from,
        hint,
        methods,
    })

    const HintTo = withHint({
        name: to,
        hint,
        methods,
    })

    return (
        <div data-label="true">
            <LabelText text={label || name} htmlFor={from} small={small} required={rest.required} />

            {
                HintFrom
                    ? <HintFrom>{inputFrom}</HintFrom>
                    : <div className={styles.pos}>{inputFrom}</div>
            }

            <label className={rangeStyles.pseudo_label} htmlFor={to}>do</label>

            {
                HintTo
                    ? <HintTo>{inputTo}</HintTo>
                    : <div className={styles.pos}>{inputTo}</div>
            }

        </div>
    )
}

function Input ({ name, register, rest }) {
    const { unit, ..._rest } = rest
    const input = <input type="number" id={name} name={name} {...register(name)} {..._rest}
        className={styles.input}
        autoComplete="off" />

    return unit
        ? <Unit unit={unit}>
            { input }
          </Unit>
        : input
}

function Unit ({ unit, children }) {
    return (
        <span className={rangeStyles.unit_wrapper}>
            { children }
            <span className={rangeStyles.unit}>{unit}</span>
        </span>
    )
}

export default RangeInput