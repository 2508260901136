import Checkbox from '@components/form/Checkbox'
import useT from '@hooks/useTranslation'
import styles from 'styles/CheckboxesAll.module.css'

const FALSE_CHECKBOX_VALUE = false

const CheckboxesAll = ({
    name, label, options, tOptions = options, names = [],
    vertical, allOption = true,
    methods, ...rest }) => {

    const allOptionName = allOption && getName(name, 'wszystkie')

    const setValue = (name, value) => {
        methods.onChange({ name, value })
        methods.setValue(name, value)
    }

    const clearOthers = allOption
        ? (e) => {
            const checked = e.target.checked
            if (checked) {
                options.forEach((opt, i) => {
                    const optName = names[i] || getName(name, opt)
                    setValue(optName, FALSE_CHECKBOX_VALUE)
                })
            }
          }
        : undefined

    const clearAllOption = allOption
        ? (e) => {
            const checked = e.target.checked
            if (checked) setValue(allOptionName, FALSE_CHECKBOX_VALUE)
          }
        : undefined

    return (
        <div className={vertical
            ? styles.wrapper_vertical
            : styles.wrapper}>

            { label && <h3>{label}</h3> }
            {
                options.map((opt, i) => {
                    const optName = names[i] || getName(name, opt)
                    const optLabel = useT(tOptions[i])
                    return <Checkbox
                        key={optName}
                        name={optName}
                        label={optLabel}
                        register={methods.register}
                        onClick={clearAllOption} {...rest} />
                })
            }
            {
                allOption && <Checkbox
                    onClick={clearOthers}
                    name={allOptionName}
                    label={useT('Wszystkie')} {...rest} />
            }
        </div>
    )
}

function getName (name, opt) {
    return name + '-' + opt
}

export default CheckboxesAll