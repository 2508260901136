import Input from '@components/form/Input'
import RangeInput from '@components/form/RangeInput'
import RadioInput from '@components/form/RadioInput'
import Checkbox from '@components/form/Checkbox'
import CheckboxesAll from '@components/form/CheckboxesAll'
import DateTimeInput from '@components/form/DateTimeInput'
import Textarea from '@components/form/Textarea'
import Complex from '@components/form/Complex'
import Blank from '@components/form/Blank'
import useT from '@hooks/useTranslation'

import ConditionalSection from '@components/form/ConditionalSection'
import LinkSection from '@components/form/LinkSection'

const RenderOutInputs = ({
    Wrapper,
    inputs,
    renderWrapper = true,
    shouldRenderWrapper = null,
    ...rest }) => {

    return inputs.map(item => {
        const { t, type = 'text', ...other } = item

        if (type === 'conditional') {
            return <ConditionalSection key={other.name} {...other} {...rest} />
        } else if (type === 'links') {
            return <LinkSection key='links' {...other} />
        }

        const input = ProperInput(type, {
            label: t
                ? useT(t)
                : other.label || '',

            placeholder: ' ',
            ...other,
            ...rest,
        })

        const useWrapper = shouldRenderWrapper
            ? shouldRenderWrapper(type)
            : type !== 'blank' && renderWrapper

        return useWrapper && Wrapper
            ? <Wrapper key={other.name || t}>
                { input }
              </Wrapper>

            : input
    })
}

function ProperInput (type, props) {
    const { name } = props
    switch (type) {
        case 'text': return <Input key={name} {...props} />
        case 'range': return <RangeInput key={name} {...props} />
        case 'radio': return <RadioInput key={name} {...props} />
        case 'checkbox': return <Checkbox key={name} {...props} />
        case 'all-checkbox': return <CheckboxesAll key={name} {...props} />
        case 'multi-checkbox': return <CheckboxesAll key={name} {...props}
            allOption={false} vertical />
        case 'h': return <h3 key={props.label}><br/>{props.label}</h3>
        case 'blank': return <Blank key="b" {...props} />
        case 'datetime': return <DateTimeInput key={name} {...props} />
        case 'textarea': return <Textarea key={name} {...props} />
        case 'complex': return <Complex key={name} {...props} />
        default: return <Input key={name} type={type} {...props} />
    }
}

export default RenderOutInputs

