import styles from 'styles/Input.module.css'

const DateTimeInput = ({ register, name, label, methods, ...rest }) => {
    // const [value, setValue] = useState(new Date())

    // useEffect(() => {
    //     methods.onChange({ name, value })
    //     methods.setValue(name, value)
    // }, [value])

    // TODO: localize, Firefox support
    return (
        <label htmlFor={name}>
            <span className={styles.label_text} required={rest.required}>{ label || name }</span>
            <input id={name}
                name={name}
                type="date" // datetime-local
                className={styles.input}
                {...register(name)} />
        </label>
    )
}

export default DateTimeInput