import Input from '@components/form/Input'
import MultipleSelect from '@components/form/MultipleSelect'
import useT from '@hooks/useTranslation'

import styles from 'styles/display/ComplexInputs.module.css'

const COMPONENTS = {
    'locationRange': LocationRange,
    'multipleSelect': MultipleSelect,
}

export default function Complex ({ name, render, type, ...props }) {
    const Component = COMPONENTS[render]
    return Component
        ? <Component {...props} />
        : null
}

function LocationRange ({ inputs: { lokalizacja, zasieg }, ...rest }) {
    const { t: tLocation, ...location } = lokalizacja
    const { t: tRange, ...range } = zasieg

    location.label = useT(tLocation)
    range.label = useT(tRange)

    return <div className={`${styles.complex} ${styles['two-inline']}`}>
        <Input {...rest} {...location}  />
        <Input {...rest} {...range}  />
    </div>
}